<template>
	<div class="quote-request-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !requestNo"
		         v-on:ok="deleteQuoteRequest"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Quote Request</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalRequestNo" class="font-weight-bold">Request No.</label>
					<input type="text" id="modalRequestNo" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': requestNoInvalid}"
					       v-model="requestNo"
					       v-on:input="requestNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-50 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-0 overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveQuoteRequest"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!quoteRequest"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="form-group position-relative w-100">
					<label for="requestNo" class="font-weight-bold">Request No.</label>
					<input type="text" id="requestNo" placeholder=""
					       class="form-control position-relative"
					       v-bind:value="localQuoteRequest.requestNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="supplier" class="font-weight-bold">Supplier</label>
					<input type="text" id="supplier" class="form-control position-relative"
					       placeholder="Choose Supplier From The Table"
					       v-bind:class="invalidInputs.supplier"
					       v-bind:value="localQuoteRequest.supplierName" readonly>
				</div>
				<div class="subform position-relative w-100 h-auto mb-3 border border-custom-light rounded"
				     v-for="(item, index) in localQuoteRequest.items" v-bind:key="index">
					<div class="controls position-relative w-100 p-2 d-flex justify-content-center align-items-center">
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localQuoteRequest.items.length === 1"
						        v-on:click="addDeleteItems('delete', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'minus']">
							</font-awesome-icon>
						</button>
						<h4 class="title position-relative px-4 m-0 text-truncate">Item {{index + 1}}</h4>
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localQuoteRequest.items.length >= 50"
						        v-on:click="addDeleteItems('add', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'plus']">
							</font-awesome-icon>
						</button>
					</div>
					<div class="content position-relative w-100 p-3">
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-name${index}`" class="font-weight-bold">Name</label>
							<input type="text" v-bind:id="`item-name${index}`" placeholder="Item Name"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].name"
							       v-bind:value="item.name"
							       v-on:input="updateItems('name', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-quantity${index}`" class="font-weight-bold">Quantity</label>
							<input type="text" v-bind:id="`item-quantity${index}`" placeholder="Quantity"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].quantity"
							       v-bind:value="item.quantity"
							       v-on:input="updateItems('quantity', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-descriptions${index}`" class="font-weight-bold">Descriptions</label>
							<input type="text" v-bind:id="`item-descriptions${index}`" placeholder="Descriptions"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].descriptions"
							       v-bind:value="item.descriptions"
							       v-on:change="updateItems('descriptions', $event.target.value, index)">
						</div>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="shippingAddress" class="font-weight-bold">Shipping Address</label>
					<input type="text" id="shippingAddress" placeholder="Shipping Address"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.shippingAddress"
					       v-bind:value="localQuoteRequest.shippingAddress"
					       v-on:input="updateQuoteRequest('shippingAddress', $event.target.value)">
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="deliverAtDate" class="font-weight-bold text-truncate">Delivery Date</label>
						<b-form-datepicker id="deliverAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.deliverAtDT.date"
						                   v-bind:value="localQuoteRequest.deliverAtDT.date"
						                   v-on:input="updateDT('deliverAt', 'deliverAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="deliverAtTime" class="font-weight-bold text-truncate">Time</label>
						<b-form-timepicker id="deliverAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.deliverAtDT.time"
						                   v-bind:value="localQuoteRequest.deliverAtDT.time"
						                   v-on:input="updateDT('deliverAt', 'deliverAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="issuedBy" class="font-weight-bold">Order Issued By</label>
					<input type="text" id="issuedBy" class="form-control position-relative"
					       placeholder="Choose Among Your Staffs Who Issued This Order"
					       v-bind:class="invalidInputs.issuedBy"
					       v-bind:value="localQuoteRequest.issuedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="issuedAtDate" class="font-weight-bold text-truncate">Date Issued</label>
						<b-form-datepicker id="issuedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.issuedAtDT.date"
						                   v-bind:value="localQuoteRequest.issuedAtDT.date"
						                   v-on:input="updateDT('issuedAt', 'issuedAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="issuedAtTime" class="font-weight-bold text-truncate">Time Issued</label>
						<b-form-timepicker id="issuedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.issuedAtDT.time"
						                   v-bind:value="localQuoteRequest.issuedAtDT.time"
						                   v-on:input="updateDT('issuedAt', 'issuedAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="notes" class="font-weight-bold">Notes</label>
					<textarea id="notes" rows="6" class="form-control position-relative"
					          v-model="localQuoteRequest.notes"></textarea>
				</div>
				<div class="form-group position-relative w-100">
					<label for="updatedBy" class="font-weight-bold">Order Updated By</label>
					<input type="text" id="updatedBy" class="form-control position-relative"
					       placeholder="Order Not Yet Saved"
					       v-bind:value="localQuoteRequest.updatedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="updatedAtDate" class="font-weight-bold text-truncate">Date Updated</label>
						<b-form-datepicker id="updatedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localQuoteRequest.updatedAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="updatedAtTime" class="font-weight-bold text-truncate">Time Updated</label>
						<b-form-timepicker id="updatedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localQuoteRequest.updatedAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="createdBy" class="font-weight-bold">Order Created By</label>
					<input type="text" id="createdBy" class="form-control position-relative"
					       placeholder="Order Not Yet Created"
					       v-bind:value="localQuoteRequest.createdByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="createdAtDate" class="font-weight-bold text-truncate">Date Created</label>
						<b-form-datepicker id="createdAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localQuoteRequest.createdAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="createdAtTime" class="font-weight-bold text-truncate">Time Created</label>
						<b-form-timepicker id="createdAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localQuoteRequest.createdAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
			</form>
		</div>
		<div class="table-selects scroll-bar position-relative w-50 h-100 ml-3 rounded d-flex flex-column">
			<table-select title="Supplier" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="suppliersFields"
			              v-bind:data-array="suppliers"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localQuoteRequest.supplier]"
			              v-on:row-clicked="selectSupplier($event)"
			              v-bind:style="{height: '50% !important'}"></table-select>
			<table-select title="Staff" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="staffsFields"
			              v-bind:data-array="staffs"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localQuoteRequest.issuedBy]"
			              v-on:row-clicked="selectIssuedBy($event)"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}"></table-select>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import TableSelect from "@/components/functional/table/TableSelect";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import isNumeric from "validator/lib/isNumeric";
import { BModal, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { format } from "date-fns"
import { dateTimeDifferent } from "@/utility/helpers";

export default {
	name: "QuoteRequestDetails",
	
	components: {
		FormControls,
		TableSelect,
		ControlButton,
		BModal,
		BFormDatepicker,
		BFormTimepicker
	},
	
	props: {
		// props from vue router route params
		requestId: {
			type: String,
		}
	},
	
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	
	created() {
		this.initQuoteRequest();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	
	data() {
		return {
			localQuoteRequest: {
				_id: "",
				requestNo: "",
				supplier: "",
				items: [
					{
						name: "",
						quantity: "",
						descriptions: "",
					},
				],
				shippingAddress: "",
				deliverAt: "",
				issuedAt: "",
				createdAt: "",
				updatedAt: "",
				issuedBy: "",
				createdBy: "",
				updatedBy: "",
				notes: "",
				// added properties
				supplierName: "",
				issuedByName: "",
				createdByName: "",
				updatedByName: "",
				deliverAtDT: { date: "", time: "" },
				issuedAtDT: { date: "", time: "" },
				createdAtDT: { date: "", time: "" },
				updatedAtDT: { date: "", time: "" },
			},
			// use v-model on input that does not require validation
			invalidInputs: {
				supplier: "",
				shippingAddress: "",
				deliverAtDT: { date: "", time: "" },
				// can be invalid but not required
				issuedAtDT: { date: "", time: "" },
				issuedBy: "",
			},
			invalidItems: [
				{
					name: "",
					quantity: "",
					descriptions: "",
				},
			],
			suppliersFields: [
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "supplier No.", key: "supplierNo", classBinding: ["f-3"] }
			],
			staffsFields: [
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Staff No.", key: "staffNo", classBinding: ["f-3"] },
				{ display: "Gender", key: "gender", classBinding: [] },
				{ display: "Position", key: "position", classBinding: ["f-4"] },
			],
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			requestNo: "",
			requestNoInvalid: false,
		}
	},
	
	computed: {
		quoteRequest() {
			return this.$store.state.contents.quotationRequests.find(({ _id }) => _id === this.requestId);
		},
		
		savable() {
			// fall through pattern
			if (!this.quoteRequest) return true;
			// can safely access properties inside this.quoteRequest due to the first if clause in this function
			if (this.itemsDifferent(this.localQuoteRequest.items, this.quoteRequest.items)) return true;
			if (dateTimeDifferent(this.quoteRequest.deliverAt, this.localQuoteRequest.deliverAt)) return true;
			if (dateTimeDifferent(this.quoteRequest.issuedAt, this.localQuoteRequest.issuedAt)) return true;
			const keys = ["supplier", "shippingAddress", "issuedBy", "notes"];
			return keys.some(key => this.localQuoteRequest[key] !== this.quoteRequest[key]);
		},
		
		suppliers() {
			return this.$store.state.contents.suppliers.map(({ _id, name, supplierNo }) => {
				return {
					_id: _id,
					name: name,
					supplierNo: supplierNo,
				}
			});
		},
		
		staffs() {
			return this.$store.state.contents.staffs.map(({ _id, firstName, lastName, preferredName, staffNo, position, gender }) => {
				return {
					_id: _id,
					name: `${lastName} ${firstName}, ${preferredName}`,
					staffNo: staffNo,
					gender: gender,
					position: position
				};
			});
		},
	},
	
	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},
		
		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.requestNo = "";
				this.requestNoInvalid = false;
			}
		},
		
		initQuoteRequest() {
			if (this.quoteRequest) {
				const obj = {...this.quoteRequest};
				obj.items = [];
				this.invalidItems = [];
				this.quoteRequest.items.forEach(item => {
					obj.items.push({...item});
					this.invalidItems.push({ name: "", quantity: "", descriptions: "" });
				});
				const supplier = this.$store.state.contents.suppliers.find(({ _id }) => _id === obj.supplier);
				const issuedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.issuedBy);
				const createdBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.createdBy);
				const updatedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.updatedBy);
				// deliverAt, createdAt and updatedAt must present in the database, need no nullish check
				const deliverAtDT = new Date(obj.deliverAt);
				const createdAtDT = new Date(obj.createdAt);
				const updatedAtDT = new Date(obj.updatedAt);
				// added properties
				obj.supplierName = supplier ? `${supplier.name} (${supplier.supplierNo})` : "";
				obj.issuedByName = issuedBy ? `${issuedBy.lastName} ${issuedBy.firstName}, ${issuedBy.preferredName} (${issuedBy.staffNo})` : "";
				obj.createdByName = createdBy ? `${createdBy.lastName} ${createdBy.firstName}, ${createdBy.preferredName} (${createdBy.staffNo})` : "N/A";
				obj.updatedByName = updatedBy ? `${updatedBy.lastName} ${updatedBy.firstName}, ${updatedBy.preferredName} (${updatedBy.staffNo})` : "N/A";
				obj.deliverAtDT = { date: format(deliverAtDT, "yyyy-MM-dd"), time: format(deliverAtDT, "HH:mm:ss")};
				obj.createdAtDT = { date: format(createdAtDT, "yyyy-MM-dd"), time: format(createdAtDT, "HH:mm:ss")};
				obj.updatedAtDT = { date: format(updatedAtDT, "yyyy-MM-dd"), time: format(updatedAtDT, "HH:mm:ss")};
				if (obj.issuedAt) {
					const issuedAtDT = new Date(obj.issuedAt);
					obj.issuedAtDT = { date: format(issuedAtDT, "yyyy-MM-dd"), time: format(issuedAtDT, "HH:mm:ss")};
				} else {
					obj.issuedAtDT = { date: "", time: "" };
				}
				this.localQuoteRequest = obj;
			} else {
				this.localQuoteRequest._id = uuidv4();
				this.localQuoteRequest.requestNo = "REQ-" + this.localQuoteRequest._id.split("-")[0].toUpperCase();
			}
		},
		
		itemsDifferent(arr1, arr2) {
			if (arr1.length === arr2.length) return arr1.some(item =>
				// if arr2 contains an exact match, return false to keep checking the next item
				!arr2.some(({ name, quantity, descriptions }) =>
					item.name === name && item.quantity === quantity && item.descriptions === descriptions
				)
			)
			return true;
		},
		
		addDeleteItems(option, index) {
			if (option === "add") {
				this.localQuoteRequest.items.splice(index + 1, 0, { name: "", quantity: "", descriptions: "" });
				this.invalidItems.splice(index + 1, 0, { name: "", quantity: "", descriptions: "" });
			} else {
				this.localQuoteRequest.items.splice(index, 1);
				this.invalidItems.splice(index, 1);
			}
		},
		
		selectSupplier(supplier) {
			this.localQuoteRequest.supplier = supplier._id;
			this.localQuoteRequest.supplierName = `${supplier.name} (${supplier.supplierNo})`;
			this.invalidInputs.supplier = "";
		},
		
		selectIssuedBy(staff) {
			this.localQuoteRequest.issuedBy = staff._id;
			this.localQuoteRequest.issuedByName = `${staff.name} (${staff.staffNo})`;
			this.invalidInputs.issuedBy = "";
		},
		
		updateQuoteRequest(key, value) {
			this.localQuoteRequest[key] = value;
			// check if value is just white spaces
			this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
		},
		
		updateDT(key, keyDT, dateTime, value) {
			this.localQuoteRequest[keyDT][dateTime] = value;
			this.invalidInputs[keyDT][dateTime] = "";
			if (dateTime === "date") {
				this.localQuoteRequest[key] = this.localQuoteRequest[keyDT].time ?
					new Date(`${value}T${this.localQuoteRequest[keyDT].time}`).toISOString() : value;
			} else {
				this.localQuoteRequest[key] = this.localQuoteRequest[keyDT].date ?
					new Date(`${this.localQuoteRequest[keyDT].date}T${value}`).toISOString() : value;
			}
		},
		
		updateItems(key, value, index) {
			this.localQuoteRequest.items[index][key] = value;
			if (key === "quantity") {
				this.invalidItems[index][key] = isNumeric(value) && parseFloat(value) >= 0 ? "" : "invalid";
			} else {
				this.invalidItems[index][key] = value.trim().length > 0 ? "" : "invalid";
			}
		},
		
		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const quoteRequestKeys = ["supplier", "shippingAddress"];
			const itemKeys = ["name", "quantity", "descriptions"];
			quoteRequestKeys.forEach(key => {
				if (!this.localQuoteRequest[key] ||
					this.localQuoteRequest[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			this.invalidItems.forEach((item, index) => itemKeys.forEach(key => {
				if (!this.localQuoteRequest.items[index][key] ||
					this.localQuoteRequest.items[index][key].trim().length === 0) item[key] = "invalid"; //object reference
			}));
			if (this.localQuoteRequest.deliverAt) {
				if (!this.localQuoteRequest.deliverAtDT.date) this.invalidInputs.deliverAtDT.date = "invalid";
				if (!this.localQuoteRequest.deliverAtDT.time) this.invalidInputs.deliverAtDT.time = "invalid";
			} else {
				this.invalidInputs.deliverAtDT.date = "invalid";
				this.invalidInputs.deliverAtDT.time = "invalid";
			}
			if (this.localQuoteRequest.issuedBy || this.localQuoteRequest.issuedAt) {
				if (!this.localQuoteRequest.issuedBy)this.invalidInputs.issuedBy = "invalid";
				if (!this.localQuoteRequest.issuedAtDT.date) this.invalidInputs.issuedAtDT.date = "invalid";
				if (!this.localQuoteRequest.issuedAtDT.time) this.invalidInputs.issuedAtDT.time = "invalid";
			}
			return Object.keys(this.invalidInputs).some(key => {
				if (key.includes("DT")) {
					return this.invalidInputs[key].date === "invalid" ||
						this.invalidInputs[key].time === "invalid";
				} else {
					return this.invalidInputs[key] === "invalid";
				}
			}) || this.invalidItems.some(item => itemKeys.some(key => item[key] === "invalid"));
		},
		
		async saveQuoteRequest() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				const deleteKeys = ["supplierName", "issuedByName", "createdByName", "updatedByName",
					"deliverAtDT", "issuedAtDT", "createdAtDT", "updatedAtDT"];
				const now = new Date().toISOString();
				const quoteRequest = {...this.localQuoteRequest};
				if (!quoteRequest.createdAt) {
					quoteRequest.createdAt = now;
					quoteRequest.createdBy = this.$store.state.user.user._id;
				}
				quoteRequest.updatedAt = now;
				quoteRequest.updatedBy = this.$store.state.user.user._id;
				deleteKeys.forEach(key => delete quoteRequest[key]);
				await this.$store.dispatch("contents/updateContent", { key: "quotationRequests", value: quoteRequest});
				if (this.requestId) {
					this.initQuoteRequest();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/supplier/request/${quoteRequest._id}`);
					// need to run initQuoteRequest after route change since quoteRequestId prop was undefined
					this.initQuoteRequest();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Quote Request Created!";
				}
			}
		},
		
		async deleteQuoteRequest(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.requestNo !== this.quoteRequest.requestNo) this.requestNoInvalid = true;
			} else if (this.requestNo !== this.quoteRequest.requestNo) {
				bvModalEvent.preventDefault();
				this.requestNoInvalid = true;
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "quotationRequests", _id: this.quoteRequest._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/supplier/request");
			}
		},
		
		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/supplier/request");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
</style>